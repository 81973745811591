import { LogoutIcon } from '@heroicons/react/outline';
import { googleLogout } from '@react-oauth/google';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const LogoutButton = () => {
  const navigate = useNavigate();
  return (
    <LogoutIcon
      className="w-7 h-7 cursor-pointer"
      onClick={() => {
        googleLogout();
        navigate('/logout');
      }}
    />
  );
};

export default LogoutButton;
