import { MailIcon } from '@heroicons/react/outline';
import { Avatar } from '@mui/material';
import BottomSheet from 'components/BottomSheet';
import React, { useState } from 'react';
import { User } from '../../../../generated/graphql';
import { truncateFullName } from '../../../../utils/global.utils';

type LecturerInfoProps = {
  lecturer: Pick<User, 'fullName' | 'avatarUrl' | 'email'>;
};

const LecturerInfo: React.FC<LecturerInfoProps> = ({
  lecturer: { fullName, avatarUrl, email },
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div
        className="flex items-start flex-col cursor-pointer"
        onClick={() => setIsOpen(true)}
        data-testid="schedule-lecturer-fullname"
      >
        {truncateFullName(fullName)}
      </div>
      <BottomSheet
        handleClose={() => setIsOpen(false)}
        handleOpen={() => setIsOpen(true)}
        isOpen={isOpen}
        maxHeight={250}
      >
        <div className="relative z-50 p-6 pb-36">
          <div className="flex items-center text-2xl">
            <Avatar
              className="!w-16 !h-16 mr-2"
              src={avatarUrl ?? ''}
              alt="lecturer image"
            />
            {fullName}
          </div>
          <div className="mt-4 flex items-center text-xl">
            <MailIcon className="w-8 h-8 flex-shrink-0 mr-2" />
            <span style={{ wordBreak: 'break-word' }}>{email}</span>
          </div>
        </div>
      </BottomSheet>
    </>
  );
};

export default LecturerInfo;
