import { Chip } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { scheduleClassTypeColor } from 'utils/schedule.utils';

const ScheduleTypeHints = () => {
  const { t } = useTranslation();

  const types = {
    'LABORATORY.TEXT': (
      <Chip
        color={scheduleClassTypeColor.Laboratory}
        label={t('LABORATORY')}
        size="small"
        className="font-bold"
      />
    ),
    'PRACTICAL.TEXT': (
      <Chip
        color={scheduleClassTypeColor.Practical}
        label={t('PRACTICAL')}
        size="small"
        className="font-bold"
      />
    ),
    'SEMINAR.TEXT': (
      <Chip
        color={scheduleClassTypeColor.Seminar}
        label={t('SEMINAR')}
        size="small"
        className="font-bold"
      />
    ),

    'LECTURE.TEXT': (
      <Chip
        color={scheduleClassTypeColor.Lecture}
        label={t('LECTURE')}
        size="small"
        className="font-bold"
      />
    ),
  };
  return (
    <div className="flex justify-center items-center gap-4 flex-wrap">
      {Object.entries(types).map(([key, value]) => (
        <div key={key} className="flex items-center">
          {value}: {t(key)}
        </div>
      ))}
    </div>
  );
};

export default ScheduleTypeHints;
