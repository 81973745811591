import { SwipeableDrawer } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

export type BottomSheetProps = {
  maxHeight?: number;
  minHeight?: number;
  isOpen: boolean;
  handleOpen: () => void;
  handleClose: () => void;
};

const BottomSheet: React.FC<BottomSheetProps> = ({
  isOpen,
  handleOpen,
  handleClose,
  maxHeight,
  minHeight,
  children,
}) => {
  const height = minHeight ? { minHeight } : { maxHeight };

  return (
    <>
      <SwipeableDrawer
        anchor="bottom"
        open={isOpen}
        onClose={handleClose}
        onOpen={handleOpen}
        disableSwipeToOpen={true}
        ModalProps={{
          keepMounted: true,
        }}
        disableBackdropTransition={true}
        sx={{
          '& .MuiPaper-root': {
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
          },
        }}
      >
        <Box {...height} data-testid="schedule-lecturer-info">
          <Box padding={2} display="flex" justifyContent="center">
            <Box bgcolor={'gray'} width={100} height={6} borderRadius={3} />
          </Box>
          {children}
        </Box>
      </SwipeableDrawer>
    </>
  );
};

export default BottomSheet;
