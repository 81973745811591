import { Avatar } from '@mui/material';
import React from 'react';
import { ScheduleForLecturerQuery, User } from '../../../generated/graphql';
import QRCodeGeneratorModal from './QRCodeGeneratorModal';
import ScheduleForDay from './ScheduleForDay';

export type ScheduleForLecturerProps = {
  data: ScheduleForLecturerQuery;
  grouppedClasses: any;
};

const ScheduleForLecturer: React.FC<ScheduleForLecturerProps> = ({
  data,
  grouppedClasses,
}) => {
  return (
    <div>
      <div className="pb-16">
        <div className="text-xl text-left mb-6 flex justify-between">
          <div className="flex items-center">
            <Avatar
              className="!w-16 !h-16 mr-2"
              src={data.getUser.avatarUrl ?? ''}
              alt="lecturer image"
            />
            <p className="mb-0.5">{data?.getUser.fullName}</p>
          </div>
          <QRCodeGeneratorModal />
        </div>
        {Object.values(grouppedClasses).map((item, idx) => (
          <ScheduleForDay key={idx} dayNumber={idx + 1} dayClasses={item} />
        ))}
      </div>
    </div>
  );
};

export default ScheduleForLecturer;
