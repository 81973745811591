import {
  Action,
  combineReducers,
  configureStore,
  Reducer,
} from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { mainApi } from '../api';
import { scheduleFilterSlice } from './slices/scheduleFilter/slice';

import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { settingsSlice } from './slices/settings/slice';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['scheduleFilter'],
};

const rootReducer = combineReducers({
  [mainApi.reducerPath as string]: mainApi.reducer,
  scheduleFilter: scheduleFilterSlice.reducer,
  settings: settingsSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type Store = typeof store;
