import { useEffect } from 'react';
import { AmplitudeEvent, useAmplitude, useInitAmplitude } from './useAmplitude';

export const useInitApp = () => {
  useInitAmplitude();

  const { sendEvent } = useAmplitude();

  useEffect(() => {
    sendEvent(AmplitudeEvent.OpenApp);
  }, [sendEvent]);
};
