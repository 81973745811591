import React, { useEffect } from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';

const LogoutPage = () => {
  const navigate = useNavigate();
  const client = useApolloClient();

  useEffect(() => {
    Cookies.remove('user-auth-token');
    client.clearStore();
    console.log('Ви успішно вийшли з облікового запису');
    navigate('/login');
  }, []);

  return null;
};

export default LogoutPage;
