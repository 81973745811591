import { weekSlugs } from '../../../utils/schedule.utils';
import _ from 'lodash';
import NoScheduleForDay from './NoScheduleForDay';
import ScheduleRow from './ScheduleRow';
import { useTheme } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import { Class } from 'generated/graphql';

type ScheduleForDayProps = {
  dayClasses: any;
  dayNumber: number;
};

const ScheduleForDay: React.FC<ScheduleForDayProps> = ({
  dayClasses,
  dayNumber,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const dayClassesValues = Object.values(dayClasses);

  const {
    palette: { mode },
  } = useTheme();

  useEffect(() => {
    const day = new Date().getDay();
    if (day === dayNumber) {
      setIsOpen(true);
    }
  }, []);

  return (
    <div
      className={`rounded-md ${
        mode === 'dark' ? 'bg-black' : 'bg-white'
      } bg-opacity-80 shadow-lg my-4`}
    >
      <div
        className="flex gap-1 justify-center items-center px-4 py-2 text-center cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        {t(weekSlugs[dayNumber])}
        {isOpen ? (
          <ChevronUpIcon className="w-6 h-6" />
        ) : (
          <ChevronDownIcon className="w-6 h-6" />
        )}
      </div>
      {isOpen ? (
        dayClassesValues.length ? (
          <div>
            {dayClassesValues.map((classes: any, idx) =>
              classes ? (
                <ScheduleRow key={idx} classes={Object.values(classes)} />
              ) : (
                <NoScheduleForDay key={idx} sequence={idx + 1} />
              ),
            )}
          </div>
        ) : (
          <div className="text-center py-2">{t('NO.CLASSES')}</div>
        )
      ) : null}
    </div>
  );
};

export default ScheduleForDay;
