import React from 'react';
import { useTranslation } from 'react-i18next';
import { courseToLabel } from 'utils/schedule.utils';
import { ScheduleForStudentQuery } from '../../../generated/graphql';
import QRCodeGeneratorModal from './QRCodeGeneratorModal';
import ScheduleForDay from './ScheduleForDay';
import ScheduleTypeHints from './ScheduleTypeHints';

export type ScheduleForStudentProps = {
  data: ScheduleForStudentQuery;
  grouppedClasses: any;
};

const ScheduleForStudent: React.FC<ScheduleForStudentProps> = ({
  data,
  grouppedClasses,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="pb-16">
        <div className="text-xl text-left mb-6 flex justify-between">
          <div>
            <p className="mb-0.5">
              {`${data?.getGroup.specialty.title} ${t(
                courseToLabel[data?.getGroup.course],
              )}`}
            </p>
            <p>
              <small>{data?.getGroup.slug}</small>
            </p>
          </div>
          <QRCodeGeneratorModal />
        </div>
        <ScheduleTypeHints />
        {Object.values(grouppedClasses).map((item, idx) => (
          <ScheduleForDay key={idx} dayNumber={idx + 1} dayClasses={item} />
        ))}
      </div>
    </div>
  );
};

export default ScheduleForStudent;
