import React from 'react';
import { ClassVariantsEnum } from 'utils/schedule.utils';
import { Class } from '../../../generated/graphql';
import ScheduleItem from './ScheduleItem';
import ScheduleItemBoth from './ScheduleItemBoth';

type ScheduleRowProps = {
  classes: Class[];
};

const ScheduleRow: React.FC<ScheduleRowProps> = ({ classes }) => {
  const hasTwoVariants = (classes: Class[]) =>
    classes.some(({ variant }) => variant === ClassVariantsEnum.ODD) &&
    classes.some(({ variant }) => variant === ClassVariantsEnum.EVEN);

  const data = {
    odd: classes.filter(
      ({ variant }: any) => variant === ClassVariantsEnum.ODD,
    ),
    even: classes.filter(
      ({ variant }: any) => variant === ClassVariantsEnum.EVEN,
    ),
  };

  return (
    <>
      <div
        className="border-t-2"
        style={{ fontFamily: `'Yanone Kaffeesatz', sans-serif` }}
      >
        {hasTwoVariants(classes) ? (
          <ScheduleItemBoth {...data} />
        ) : (
          classes.map(clazz => (
            <ScheduleItem key={clazz._id} scheduleClass={clazz} />
          ))
        )}
      </div>
    </>
  );
};

export default ScheduleRow;
