import { useTheme } from '@mui/system';
import React from 'react';
import { scheduleTimeConfig } from '../../../utils/schedule.utils';

type NoScheduleForDayProps = {
  sequence: number;
};

const NoScheduleForDay: React.FC<NoScheduleForDayProps> = ({ sequence }) => {
  const {
    palette: { mode },
  } = useTheme();

  return (
    <div
      className="px-4 py-2 border-t-2"
      style={{
        backgroundImage: `${
          mode === 'dark'
            ? 'linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85))'
            : 'linear-gradient(rgba(255, 255, 255, 0.85), rgba(255, 255, 255, 0.85))'
        }, url(https://d2gg9evh47fn9z.cloudfront.net/800px_COLOURBOX19502927.jpg)`,
      }}
    >
      <span className="mr-4">{sequence}</span>
      {scheduleTimeConfig[sequence]}
    </div>
  );
};

export default NoScheduleForDay;
