export const resources = {
  en: {
    translation: {
      AUTH: 'Authorization',
      'AUTH.MESSAGE': 'Auth via corporation mail',
      'AUTH.BUTTON': 'Auth',
      'HOME.TITLE': 'Home',
      'HOME.WELCOME': 'Welcome to "UzNU Digital schedule"!',
      'MENU.SCHEDULE': 'Schedule',
      'MENU.POLL': 'Poll',
      MENU: 'Menu',
      QRCODE: 'QR-code',
      'QRCODE.SCAN': 'Scan',
      'QRCODE.GENERATE': 'Generate',
      'QRCODE.GENERATED': 'Generated QR-code',
      'SEARCH.HISTORY': 'Search history:',
      'SCHEDULE.PAGE.DEFAULT': 'Use filter to find your schedule',
      FILTER: 'Filter',
      'FILTER.STUDENT': 'Student',
      'FILTER.LECTURER': 'Lecturer',
      'FILTER.CLEAR': 'Clear',
      'FILTER.FACULTY': 'Choose faculty',
      'FILTER.SPECIALTY': 'Choose specialty',
      'FILTER.COURSE': 'Choose course',
      'FILTER.GROUP': 'Choose group',
      'FILTER.TO.SCHEDULE': 'To schedule',
      'FILTER.LECTURER.PLACEHOLDER': 'Choose lecturer',
      'COURSE.1B': '1st year',
      'COURSE.2B': '2nd year',
      'COURSE.3B': '3rd year',
      'COURSE.4B': '4th year',
      'COURSE.5B': '5th year',
      'COURSE.6B': '6th year',
      'COURSE.7B': '7th year',
      'COURSE.8B': '8th year',
      'COURSE.ST1': '1th year st',
      'COURSE.ST2': '2th year st',
      'COURSE.ST3': '3th year st',
      'COURSE.1M': '1st year master deg',
      'COURSE.2M': '2st year master deg',
      'COURSE.3M': '3st year master deg',
      'COURSE.4M': '4st year master deg',
      'COURSE.5M': '5st year master deg',
      'COURSE.6M': '6st year master deg',
      MONDAY: 'Monday',
      TUESDAY: 'Tuesday',
      WEDNESDAY: 'Wednesday',
      THURSDAY: 'Thursday',
      FRIDAY: 'Friday',
      SATURDAY: 'Saturday',
      NOTE: 'Note',
      GROUPS: 'Groups',
      LABORATORY: 'Lab',
      SEMINAR: 'Sem',
      PRACTICAL: 'Prac',
      LECTURE: 'Lect',
      'LABORATORY.TEXT': 'Laboratory',
      'SEMINAR.TEXT': 'Seminar',
      'PRACTICAL.TEXT': 'Practical',
      'LECTURE.TEXT': 'Lecture',
      'NO.CLASSES': 'No classes',
    },
  },
  ua: {
    translation: {
      AUTH: 'Авторизація',
      'AUTH.MESSAGE': 'Авторизуйтесь з корпоративної пошти',
      'AUTH.BUTTON': 'Авторизуйтесь',
      'HOME.TITLE': 'Головна',
      'HOME.WELCOME': 'Вас вітає "Електронний розклад УжНУ"!',
      'MENU.SCHEDULE': 'Розклад',
      'MENU.POLL': 'Опитування',
      MENU: 'Меню',
      QRCODE: 'QR-код',
      'QRCODE.SCAN': 'Відскануйте',
      'QRCODE.GENERATE': 'Згенеруйте',
      'QRCODE.GENERATED': 'Згенерований QR-код',
      'SEARCH.HISTORY': 'Історія пошуку',
      'SCHEDULE.PAGE.DEFAULT': 'Скористуйтесь фільтром, щоб знайти ваш розклад',
      FILTER: 'Фільтр',
      'FILTER.STUDENT': 'Студент',
      'FILTER.LECTURER': 'Викладач',
      'FILTER.CLEAR': 'Очистити',
      'FILTER.FACULTY': 'Оберіть факультет',
      'FILTER.SPECIALTY': 'Оберіть спеціальність',
      'FILTER.COURSE': 'Оберіть курс',
      'FILTER.GROUP': 'Оберіть групу',
      'FILTER.TO.SCHEDULE': 'До розкладу',
      'FILTER.LECTURER.PLACEHOLDER': 'Оберіть викладача',
      'COURSE.1B': 'Курс 1',
      'COURSE.2B': 'Курс 2',
      'COURSE.3B': 'Курс 3',
      'COURSE.4B': 'Курс 4',
      'COURSE.5B': 'Курс 5',
      'COURSE.6B': 'Курс 6',
      'COURSE.7B': 'Курс 7',
      'COURSE.8B': 'Курс 8',
      'COURSE.ST1': 'Курс 1 ст',
      'COURSE.ST2': 'Курс 2 ст',
      'COURSE.ST3': 'Курс 3 ст',
      'COURSE.1M': 'Курс 1 магістр',
      'COURSE.2M': 'Курс 2 магістр',
      'COURSE.3M': 'Курс 3 магістр',
      'COURSE.4M': 'Курс 4 магістр',
      'COURSE.5M': 'Курс 5 магістр',
      'COURSE.6M': 'Курс 6 магістр',
      MONDAY: 'Понеділок',
      TUESDAY: 'Вівторок',
      WEDNESDAY: 'Середа',
      THURSDAY: 'Четвер',
      FRIDAY: "П'ятниця",
      SATURDAY: 'Субота',
      NOTE: 'Примітка',
      GROUPS: 'Групи',
      LABORATORY: 'Лаб',
      SEMINAR: 'Сем',
      PRACTICAL: 'Прак',
      LECTURE: 'Лекц',
      'LABORATORY.TEXT': 'Лабораторна',
      'SEMINAR.TEXT': 'Семінар',
      'PRACTICAL.TEXT': 'Практичне',
      'LECTURE.TEXT': 'Лекція',
      'NO.CLASSES': 'Немає пар',
    },
  },
};
