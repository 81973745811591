import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import './index.css';

import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

import { ApolloProvider } from '@apollo/client';
import apolloClient from './services/apollo.service';
import { store } from './store';
import { ToggleThemeContextProvider } from 'context/colorModeContext';
import './utils/i18n';

let persistor = persistStore(store);

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ApolloProvider client={apolloClient}>
          <ToggleThemeContextProvider>
            <App />
          </ToggleThemeContextProvider>
        </ApolloProvider>
      </PersistGate>
    </Provider>
  </BrowserRouter>,
  document.getElementById('root'),
);

serviceWorkerRegistration.register({
  onUpdate: registration => {
    console.log('updateee');
    console.log(window.location.reload);
    console.log(registration.waiting);
    registration.waiting?.postMessage({ type: 'SKIP_WAITING' });

    (window.location as any).reload(true);
  },
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
