import { useState } from 'react';
import { QrcodeIcon, XIcon } from '@heroicons/react/outline';
import QRCode from 'react-qr-code';
import { Box, Typography } from '@mui/material';
import { Modal } from '@mui/material';
import {
  AmplitudeEvent,
  useAmplitude,
} from '../../../utils/hooks/useAmplitude';
import { useTranslation } from 'react-i18next';

const QRCodeGeneratorModal = () => {
  const { t } = useTranslation();
  const { sendEvent } = useAmplitude();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
    sendEvent(AmplitudeEvent.ScheduleGenerateGRCodeBtnClicked);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <div
        onClick={showModal}
        className="text-3xl text-center flex flex-col items-center whitespace-nowrap mr-1 cursor-pointer"
      >
        <QrcodeIcon className="w-10 h-10" />
        <span className="text-xs">{t('QRCODE.GENERATE')}</span>
        <span className="text-xs w-12">{t('QRCODE')}</span>
      </div>
      <Modal
        sx={{ outline: 0 }}
        open={isModalVisible}
        onClose={handleCancel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 325,
            bgcolor: 'white',
            boxShadow: 24,
            borderRadius: '5px',
            p: 4,
          }}
        >
          <XIcon
            className="w-6 h-6 absolute right-[-10px] top-[-10px] bg-red-700 rounded-full text-white"
            onClick={handleCancel}
          />
          <Typography id="modal-modal-title">
            {t('QRCODE.GENERATED')}
          </Typography>
          <QRCode
            className="block mx-auto mt-4"
            value={window.location.href}
            level="H"
            size={200}
          />
        </Box>
      </Modal>
    </>
  );
};

export default QRCodeGeneratorModal;
