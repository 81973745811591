import { gql } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { groupByDay } from 'utils/schedule.utils';
import {
  useScheduleForLecturerQuery,
  useScheduleForStudentQuery,
} from '../../../generated/graphql';
import ScheduleForDaySkeleton from './ScheduleForDaySkeleton';
import ScheduleForLecturer from './ScheduleForLecturer';
import ScheduleForStudent from './ScheduleForStudent';

export const ScheduleForStudentQuery = gql`
  query ScheduleForStudent($_id: String!) {
    getGroup(_id: $_id) {
      _id
      slug
      course
      specialty {
        _id
        title
      }
      classes {
        _id
        title
        classroom {
          _id
          slug
        }
        lecturer {
          _id
          fullName
          avatarUrl
          email
        }
        sequence
        day
        variant
        link
        note
        group {
          _id
          slug
          course
          specialty {
            _id
            title
          }
        }
        type
      }
    }
  }
`;

export const ScheduleForLecturerQuery = gql`
  query ScheduleForLecturer($_id: String!) {
    getUser(_id: $_id) {
      _id
      fullName
      avatarUrl
      email
      classes {
        _id
        title
        classroom {
          _id
          slug
        }
        lecturer {
          _id
          fullName
          avatarUrl
          email
        }
        sequence
        link
        day
        variant
        note
        group {
          _id
          slug
          course
          specialty {
            _id
            title
          }
        }
        type
      }
    }
  }
`;

const ScheduleForGroup = () => {
  const { t } = useTranslation();
  const [searchedParams] = useSearchParams();

  const { data: studentClassesData, loading: studentClassesDataLoading } =
    useScheduleForStudentQuery({
      variables: {
        _id: searchedParams.get('group') ?? '',
      },
      skip: !searchedParams.get('group'),
    });

  const { data: lecturerClassesData, loading: lecturerClassesDataLoading } =
    useScheduleForLecturerQuery({
      variables: {
        _id: searchedParams.get('lecturer') ?? '',
      },
      skip: !searchedParams.get('lecturer'),
    });

  if (studentClassesData?.getGroup) {
    return (
      <ScheduleForStudent
        data={studentClassesData}
        grouppedClasses={groupByDay(studentClassesData.getGroup)}
      />
    );
  }

  if (lecturerClassesData?.getUser) {
    return (
      <ScheduleForLecturer
        data={lecturerClassesData}
        grouppedClasses={groupByDay(lecturerClassesData?.getUser)}
      />
    );
  }

  if (
    (searchedParams.get('group') || searchedParams.get('lecturer')) &&
    (studentClassesDataLoading || lecturerClassesDataLoading)
  ) {
    return <ScheduleForDaySkeleton />;
  }

  return (
    <div className="flex-grow flex flex-col items-center justify-center">
      <img
        src="/img/schedule/no-filter.png"
        alt="poll"
        className="!block mx-auto w-64"
      />
      <div className="max-w-[280px] text-center justify-center font-medium">
        {t('SCHEDULE.PAGE.DEFAULT')}
      </div>
    </div>
  );
};

export default ScheduleForGroup;
