import { TrashIcon } from '@heroicons/react/outline';
import React from 'react';
import { useTranslation } from 'react-i18next';

export type ClearFilterButtonProps = {
  clearFilter: Function;
};

const ClearFilterButton: React.FC<ClearFilterButtonProps> = ({
  clearFilter,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <button
        onClick={() => clearFilter()}
        className="text-3xl items-center flex flex-col left-6 top-7 absolute"
      >
        <TrashIcon className="w-8 h-8" />
        <span className="text-xs">{t('FILTER.CLEAR')}</span>
      </button>
    </>
  );
};

export default ClearFilterButton;
