import { gql } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useAuthViaGoogleMutation } from '../../../generated/graphql';
import { useGoogleLogin } from '@react-oauth/google';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const AuthViaGoogleMutation = gql`
  mutation authViaGoogle($input: AuthViaGoogleInput!) {
    authViaGoogle(input: $input) {
      token
      user {
        _id
        fullName
        role
        permissions
      }
    }
  }
`;

const LoginForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [authViaGoogle] = useAuthViaGoogleMutation({
    onCompleted(data) {
      if (data) {
        Cookies.set('user-auth-token', data.authViaGoogle.token, {
          expires: 7,
        });
        navigate('/');
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const onSuccess = (res: any) => {
    authViaGoogle({
      variables: {
        input: {
          googleAccessToken: res.access_token,
        },
      },
    });
  };

  const onError = (...args: any) => {
    console.log(args);
  };

  const login = useGoogleLogin({
    onSuccess,
    onError,
  });

  return (
    <div className="w-[400px] p-4 bg-white shadow-lg mx-3 rounded-md border flex flex-col gap-2">
      <div className="text-center text-2xl">{t('AUTH')}</div>
      <div className="text-center w-full">{t('AUTH.MESSAGE')}</div>
      <Button
        variant="outlined"
        className="block mx-auto mt-2"
        onClick={() => login()}
      >
        {t('AUTH.BUTTON')}
      </Button>
    </div>
  );
};

export default LoginForm;
