import { Snackbar } from '@mui/material';
import { Class } from 'generated/graphql';
import React, { FC, useCallback, useMemo } from 'react';
import { ClassVariantsEnum } from 'utils/schedule.utils';
import VariantClass from './ScheduleClasses/VariantClass';

type ScheduleItemBothProps = {
  even: Class[];
  odd: Class[];
};

const ScheduleItemBoth: FC<ScheduleItemBothProps> = ({ even, odd }) => {
  const [clipboardSnackbar, setClipboardSnackbar] = React.useState(false);

  const onCodeClick = useMemo(
    () => (code: string | undefined | null) => {
      if (code) {
        window.navigator.clipboard.writeText(code);
        setClipboardSnackbar(true);
      }
    },
    [],
  );

  const handleCloseClipboardSnackbar = useCallback(
    (event: React.SyntheticEvent | Event, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }
      setClipboardSnackbar(false);
    },
    [],
  );

  return (
    <>
      <div className="flex items-stretch">
        <div className={`border-r-2 w-1/2 flex flex-1 items-center flex-col`}>
          {odd.map(clazz => (
            <VariantClass
              key={clazz._id}
              class={clazz}
              onCodeClick={onCodeClick}
            />
          ))}
        </div>
        <div className={`w-1/2 flex flex-1 items-center flex-col`}>
          {even.map(clazz => (
            <VariantClass
              key={clazz._id}
              class={clazz}
              onCodeClick={onCodeClick}
            />
          ))}
        </div>
      </div>
      <Snackbar
        open={clipboardSnackbar}
        onClose={handleCloseClipboardSnackbar}
        autoHideDuration={3000}
        message="Код успішно скопійовано"
      />
    </>
  );
};

export default ScheduleItemBoth;
