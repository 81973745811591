export const scheduleTimeConfig = {
  1: '8:00 - 9:20',
  2: '9:40 - 11:00',
  3: '11:20 - 12:40',
  4: '13:00 – 14:20',
  5: '14:40 – 16:00',
  6: '16:20 – 17:40',
  7: '18:00 – 19:20',
} as Record<number, string>;

export enum ClassVariantsEnum {
  ODD = 'ODD',
  EVEN = 'EVEN',
  BOTH = 'BOTH',
}

export const weekSlugs = {
  1: 'MONDAY',
  2: 'TUESDAY',
  3: 'WEDNESDAY',
  4: 'THURSDAY',
  5: `FRIDAY`,
  6: `SATURDAY`,
} as Record<number, string>;

export const courseToLabel = {
  b1: 'COURSE.1B',
  b2: 'COURSE.2B',
  b3: 'COURSE.3B',
  b4: 'COURSE.4B',
  b5: 'COURSE.5B',
  b6: 'COURSE.6B',
  b7: 'COURSE.7B',
  b8: 'COURSE.8B',
  st1: 'COURSE.ST1',
  st2: 'COURSE.ST2',
  st3: 'COURSE.ST3',
  m1: 'COURSE.1M',
  m2: 'COURSE.2M',
  m3: 'COURSE.3M',
  m4: 'COURSE.4M',
  m5: 'COURSE.5M',
  m6: 'COURSE.6M',
} as Record<string, string>;

export enum ScheduleClassEnum {
  Laboratory = 'Laboratory',
  Seminar = 'Seminar',
  Practical = 'Practical',
  Lecture = 'Lecture',
}

export const scheduleClassType = {
  [ScheduleClassEnum.Laboratory]: 'LABORATORY',
  [ScheduleClassEnum.Seminar]: 'SEMINAR',
  [ScheduleClassEnum.Practical]: 'PRACTICAL',
  [ScheduleClassEnum.Lecture]: 'LECTURE',
} as Record<ScheduleClassEnum, string>;

export const scheduleClassTypeColor = {
  [ScheduleClassEnum.Laboratory]: 'success',
  [ScheduleClassEnum.Seminar]: 'secondary',
  [ScheduleClassEnum.Practical]: 'warning',
  [ScheduleClassEnum.Lecture]: 'info',
} as Record<ScheduleClassEnum, any>;

export const groupByDay = (obj: any) => {
  const days = { 1: {}, 2: {}, 3: {}, 4: {}, 5: {}, 6: {} } as any;

  if (obj) {
    for (const classData of obj?.classes) {
      if (!days[classData.day][classData.sequence]) {
        days[classData.day][classData.sequence] = [];
      }
      days[classData.day][classData.sequence].push(classData);
    }

    for (let i = 1; i < 6; i++) {
      const lastClassIndex = Object.keys(days[i]).sort(
        (a, b) => parseInt(b) - parseInt(a),
      )[0];

      for (let j = 1; j <= parseInt(lastClassIndex); j++) {
        if (!days[i][j]) {
          days[i][j] = null;
        }
      }
    }
  }

  return days;
};
