import { useSelector } from 'react-redux';
import {
  getFilterHistory,
  removeFromFilterHistory,
} from '../../../store/slices/scheduleFilter/slice';

import { Chip, Stack, Typography, Box, Paper } from '@mui/material';

import { useDispatch } from 'react-redux';

import { useSearchParams } from 'react-router-dom';
import { useCallback } from 'react';
import {
  AmplitudeEvent,
  useAmplitude,
} from '../../../utils/hooks/useAmplitude';
import { useTranslation } from 'react-i18next';

const ScheduleFilterHistory = () => {
  const { t } = useTranslation();
  const { sendEvent } = useAmplitude();
  const [_, setSearchParams] = useSearchParams();
  const filterHistory = useSelector(getFilterHistory);
  const dispatch = useDispatch();

  const handleClick = useCallback(
    (filterParams: Record<string, string>) => {
      setSearchParams(filterParams);

      sendEvent(AmplitudeEvent.ExecuteScheduleFilter, {
        type: filterParams.lecturer ? 'lecturer' : 'student',
        by: 'historyItem',
      });
    },
    [setSearchParams],
  );

  const handleDelete = useCallback(
    (id: string) => {
      dispatch(removeFromFilterHistory(id));
      setSearchParams({});
    },
    [dispatch],
  );

  if (filterHistory.length === 0) {
    return null;
  }

  return (
    <Box
      sx={{
        border: '2px white dashed',
        boxShadow: 1,
        borderRadius: 2,
        backgroundColor: 'transparent',
        paddingRight: 2,
        paddingLeft: 2,
        paddingTop: 2,
        paddingBottom: 1,
        mb: 4,
      }}
    >
      <Typography
        variant="subtitle1"
        component="div"
        mb={1}
        sx={{ fontSize: 18 }}
      >
        {t('SEARCH.HISTORY')}
      </Typography>
      <Stack direction="row" spacing={2} overflow="auto" pb={2}>
        {filterHistory.map(({ id, filterParams, label }) => (
          <Chip
            key={id}
            label={label}
            onClick={() => handleClick(filterParams)}
            onDelete={() => handleDelete(id)}
          />
        ))}
      </Stack>
    </Box>
  );
};

export default ScheduleFilterHistory;
