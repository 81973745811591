import { useTheme } from '@mui/system';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

type HeaderProps = {
  label?: string;
  rightWidget?: ReactElement;
  bgColor?: string;
  bgDarkModeColor?: string;
};

const Header: React.FC<HeaderProps> = ({
  rightWidget,
  bgColor,
  bgDarkModeColor,
}) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const {
    palette: { mode },
  } = useTheme();

  return (
    <div
      className={`p-3 sticky top-0 flex  z-10 flex-row justify-between items-center font-medium text-xl border-b-2 ${
        mode === 'dark'
          ? `text-white ${bgDarkModeColor} border-[rgba(255,255,255,0.2)]`
          : `border-[rgba(0,0,0,0.1)] ${bgColor}`
      }  `}
    >
      <div className="flex items-center ">
        <img
          onClick={() => navigate('/')}
          className="cursor-pointer w-12 mr-3"
          src="https://www.uzhnu.edu.ua/uploads/root/logos/UzNU_logo_new2.png"
          alt="uzhnu-logo"
        />
        {t('HOME.TITLE')}
      </div>
      <div className="flex gap-2 items-center">{rightWidget}</div>
    </div>
  );
};

export default Header;
