import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../..';

import { equals } from 'ramda';

import { v4 } from 'uuid';

export type ScheduleFilterHistoryItem = {
  id: string;
  label: string;
  filterParams: Record<string, string>;
};

export type ScheduleFilterState = {
  filterHistory: ScheduleFilterHistoryItem[];
};

const initialState: ScheduleFilterState = {
  filterHistory: [],
};

export const scheduleFilterSlice = createSlice({
  name: 'scheduleFilter',
  initialState,
  reducers: {
    addToFilterHistory: (
      state,
      action: PayloadAction<Omit<ScheduleFilterHistoryItem, 'id'>>,
    ) => {
      const matchedHistoryItemIndex = state.filterHistory.findIndex(item =>
        equals(item.filterParams, action.payload.filterParams),
      );

      if (matchedHistoryItemIndex !== -1) {
        state.filterHistory.splice(matchedHistoryItemIndex, 1);
      }

      state.filterHistory.unshift({ id: v4(), ...action.payload });
    },
    removeFromFilterHistory: (state, action: PayloadAction<string>) => {
      state.filterHistory = state.filterHistory.filter(
        item => item.id !== action.payload,
      );
    },
  },
});

export const { addToFilterHistory, removeFromFilterHistory } =
  scheduleFilterSlice.actions;

export const getFilterHistory = createSelector(
  (state: RootState) => state.scheduleFilter,
  scheduleFilter => scheduleFilter.filterHistory,
);
