export const truncateFullName = (fullName: string) => {
  const [lastName, firstName, secondName] = fullName.split(' ');
  let res = '';
  if (lastName) {
    res += `${lastName}`;
  }
  if (firstName) {
    res += ` ${firstName[0]}.`;
  }
  if (secondName) {
    res += ` ${secondName[0]}.`;
  }
  return res;
};
