import { gql } from '@apollo/client';
import { createContext, FC, useContext } from 'react';
import { CurrentUserProvider_UserFragment } from '../generated/graphql';
import { FCWithFragments } from '../utils/graphql.utils';

type CurrentUserContextType = CurrentUserProvider_UserFragment | null;

type CurrentUserProviderProps = { value: CurrentUserContextType };

export const CurrentUserContext = createContext<CurrentUserContextType>(null);

export const useCurrentUserContext = () => useContext(CurrentUserContext);

export const CurrentUserProvider: FCWithFragments<CurrentUserProviderProps> = ({
  children,
  value,
}) => (
  <CurrentUserContext.Provider value={value}>
    {children}
  </CurrentUserContext.Provider>
);

CurrentUserProvider.fragments = {
  me: gql`
    fragment CurrentUserProvider_User on User {
      _id
      fullName
      avatarUrl
      role
      facultiesScopes {
        _id
        fullTitle
        shortTitle
      }
    }
  `,
};
