import { createTheme, PaletteMode } from '@mui/material';
import {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ThemeProvider } from '@mui/material';

export type ColorModeContextType = {
  toggleColorMode: () => void;
};

export const ToggleColorModeContext = createContext<ColorModeContextType>({
  toggleColorMode: () => {},
});

export const ToggleThemeContextProvider: FC = ({ children }) => {
  const [mode, setMode] = useState<PaletteMode>(
    !!localStorage.getItem('colorTheme')
      ? (localStorage.getItem('colorTheme') as PaletteMode)
      : window.matchMedia('(prefers-color-scheme: dark)').matches
      ? 'dark'
      : 'light',
  );

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode(prevMode => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [],
  );

  const theme = useMemo(
    () =>
      createTheme({
        spacing: 4,
        palette: {
          mode,
        },
        components: {},
      }),
    [mode],
  );

  useEffect(() => {
    localStorage.setItem('colorTheme', mode);
  }, [mode]);

  return (
    <ToggleColorModeContext.Provider
      value={{ toggleColorMode: colorMode.toggleColorMode }}
    >
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ToggleColorModeContext.Provider>
  );
};

export const useToggleColorModeContext = () =>
  useContext(ToggleColorModeContext);
