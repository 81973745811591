import { gql } from '@apollo/client';
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useStudentScheduleFilterQuery } from '../../../../generated/graphql';
import { ScheduleFilterHistoryItem } from '../../../../store/slices/scheduleFilter/slice';
import {
  AmplitudeEvent,
  useAmplitude,
} from '../../../../utils/hooks/useAmplitude';
import { courseToLabel } from '../../../../utils/schedule.utils';
import ClearFilterButton from './ClearFilterButton';

export type StudentFilterFormFields = {
  faculty: string;
  specialty: string;
  course: string;
  group: string;
};

export type FilterFieldDependencies = {
  faculty: string[];
  specialty: string[];
  course: string[];
  group: string[];
};

export type StudentFilterProps = {
  hideFilter: Function;
  addFilterQueryToHistory: (
    itemData: Omit<ScheduleFilterHistoryItem, 'id'>,
  ) => void;
};

export const StudentScheduleFilterQuery = gql`
  query StudentScheduleFilter {
    listFaculties {
      _id
      fullTitle
      specialties {
        _id
        title
        groups {
          _id
          slug
          course
        }
      }
    }
  }
`;

const studentFilterFormDefaultValues = {
  faculty: '',
  specialty: '',
  course: '',
  group: '',
};

const filterFieldDependencies: FilterFieldDependencies = {
  faculty: ['faculty'],
  specialty: ['faculty', 'specialty'],
  course: ['faculty', 'specialty', 'course'],
  group: ['faculty', 'specialty', 'course', 'group'],
};

const formFields: Array<keyof StudentFilterFormFields> = [
  'faculty',
  'specialty',
  'course',
  'group',
];

const StudentFilter: React.FC<StudentFilterProps> = ({
  hideFilter,
  addFilterQueryToHistory,
}) => {
  const { t } = useTranslation();
  const { sendEvent } = useAmplitude();
  const [searchParams, setSearchParams] = useSearchParams();

  const { setValue, control, reset, handleSubmit, watch } = useForm();

  const {
    data: studentScheduleFilterData,
    loading: studentScheduleFilterLoading,
  } = useStudentScheduleFilterQuery();

  const filterUpdate = (field: keyof StudentFilterFormFields) => {
    const fieldsToClear = formFields.filter(
      f => !filterFieldDependencies[field].includes(f),
    );
    fieldsToClear.forEach(field => setValue(field, ''));
  };

  const fields = watch();

  const onSubmit = (data: StudentFilterFormFields) => {
    hideFilter();
    setSearchParams(data);
    const specialtyData = studentScheduleFilterData?.listFaculties
      .find(fac => fac._id === data.faculty)
      ?.specialties.find(spec => spec._id === data.specialty);

    const groupData = specialtyData?.groups.find(
      group => group._id === data.group,
    );

    if (specialtyData && groupData) {
      const isLongTitle = specialtyData.title.split(' ').length > 1;
      addFilterQueryToHistory(
        isLongTitle
          ? {
              label: `${specialtyData.title
                .split(' ')
                .map(word => word[0].toUpperCase())
                .join('')} ${t(courseToLabel[groupData.course])} (${
                groupData.slug
              })`,
              filterParams: data,
            }
          : {
              label: `${specialtyData.title} ${t(
                courseToLabel[groupData.course],
              )} (${groupData.slug})`,
              filterParams: data,
            },
      );
    }

    sendEvent(AmplitudeEvent.ExecuteScheduleFilter, {
      type: 'student',
      by: 'filterButton',
    });
  };

  useEffect(() => {
    const filterFields: Record<string, string> = {};
    for (const param of searchParams.entries()) {
      filterFields[param[0] as keyof StudentFilterFormFields] = param[1];
    }
    reset(filterFields);
  }, []);

  const clearFilterFields = () => {
    setSearchParams({});
    reset(studentFilterFormDefaultValues);
  };

  const listFaculties = useMemo(
    () =>
      [...(studentScheduleFilterData?.listFaculties ?? [])].sort((a, b) =>
        a.fullTitle.localeCompare(b.fullTitle),
      ),
    [studentScheduleFilterData?.listFaculties],
  );

  const listGrops = useMemo(
    () =>
      studentScheduleFilterData?.listFaculties
        .find(fac => fac._id === fields.faculty)
        ?.specialties.find(spec => spec._id === fields.specialty)
        ?.groups.filter(group => group.course === fields.course),
    [studentScheduleFilterData?.listFaculties, fields],
  );

  return (
    <>
      <ClearFilterButton clearFilter={clearFilterFields} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack
          direction="column"
          alignItems="center"
          spacing={5}
          sx={{ mt: 14 }}
        >
          <Controller
            name="faculty"
            control={control}
            render={({ field }) => (
              <>
                <Autocomplete
                  disablePortal
                  options={listFaculties ?? []}
                  {...field}
                  getOptionLabel={option => option.fullTitle}
                  loading={studentScheduleFilterLoading}
                  disabled={studentScheduleFilterLoading}
                  onChange={(e, data) => {
                    field.onChange(data?._id ?? null);
                    filterUpdate('faculty');
                  }}
                  value={
                    listFaculties?.find(({ _id }) => field.value === _id) ??
                    null
                  }
                  fullWidth
                  renderInput={params => (
                    <TextField {...params} label={t('FILTER.FACULTY')} />
                  )}
                />
              </>
            )}
          />
          {fields.faculty && (
            <Controller
              name="specialty"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="schedule-filter-specialty-label">
                    {t('FILTER.SPECIALTY')}
                  </InputLabel>
                  <Select
                    labelId="schedule-filter-specialty-label"
                    label="Оберіть спеціальність"
                    {...field}
                    onChange={e => {
                      filterUpdate('specialty');
                      field.onChange(e);
                    }}
                  >
                    {studentScheduleFilterData?.listFaculties
                      .find(fac => fac._id === fields.faculty)
                      ?.specialties.map(({ _id, title }) => (
                        <MenuItem key={_id} value={_id}>
                          {title}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}
            />
          )}
          {fields.specialty && (
            <Controller
              name="course"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="schedule-filter-course-label">
                    {t('FILTER.COURSE')}
                  </InputLabel>
                  <Select
                    labelId="schedule-filter-course-label"
                    label="Оберіть курс"
                    {...field}
                    onChange={e => {
                      filterUpdate('course');
                      field.onChange(e);
                    }}
                  >
                    {[
                      ...new Set(
                        studentScheduleFilterData?.listFaculties
                          .find(fac => fac._id === fields.faculty)
                          ?.specialties.find(
                            spec => spec._id === fields.specialty,
                          )
                          ?.groups.map(group => group.course)
                          .sort(),
                      ),
                    ].map(courseLabel => (
                      <MenuItem key={courseLabel} value={courseLabel}>
                        {t(
                          courseToLabel[
                            courseLabel as keyof typeof courseToLabel
                          ],
                        )}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          )}
          {fields.course && (
            <Controller
              name="group"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  disablePortal
                  options={listGrops ?? []}
                  {...field}
                  getOptionLabel={option => option.slug}
                  loading={studentScheduleFilterLoading}
                  disabled={studentScheduleFilterLoading}
                  onChange={(e, data) => {
                    field.onChange(data?._id ?? null);
                    filterUpdate('group');
                  }}
                  value={
                    listGrops?.find(({ _id }) => field.value === _id) ?? null
                  }
                  fullWidth
                  renderInput={params => (
                    <TextField {...params} label={t('FILTER.GROUP')} />
                  )}
                />
              )}
            />
          )}
          {fields.group && (
            <Button type="submit" size="large">
              {t('FILTER.TO.SCHEDULE')} 🚀
            </Button>
          )}
        </Stack>
      </form>
    </>
  );
};

export default StudentFilter;
