import { UsersIcon } from '@heroicons/react/outline';
import { Chip } from '@mui/material';
import BottomSheet from 'components/BottomSheet';
import { Group } from 'generated/graphql';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { courseToLabel } from 'utils/schedule.utils';

export type ClassGroupsInfoProps = {
  groups: Group[];
};

const ClassGroupsInfo: FC<ClassGroupsInfoProps> = ({ groups }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <UsersIcon
        className="cursor-pointer w-6 h-6"
        onClick={() => setIsOpen(true)}
      />
      <BottomSheet
        isOpen={isOpen}
        handleOpen={() => setIsOpen(true)}
        handleClose={() => setIsOpen(false)}
        minHeight={300}
      >
        <div className="text-center mt-2">{t('GROUPS')}</div>
        <div className="mx-3 mt-2 flex gap-2 flex-wrap justify-center">
          {groups.map(({ _id, course, slug, specialty }) => (
            <Chip
              key={_id}
              label={`${slug}(${t(courseToLabel[course])}) ${specialty.title}`}
              size="small"
            />
          ))}
        </div>
      </BottomSheet>
    </>
  );
};

export default ClassGroupsInfo;
