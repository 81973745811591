import { gql } from '@apollo/client';
import React, { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { useLecturerScheduleFilterQuery } from '../../../../generated/graphql';
import ClearFilterButton from './ClearFilterButton';

import { Autocomplete, Button, Stack, TextField } from '@mui/material';
import { ScheduleFilterHistoryItem } from '../../../../store/slices/scheduleFilter/slice';

import { truncateFullName } from '../../../../utils/global.utils';
import {
  AmplitudeEvent,
  useAmplitude,
} from '../../../../utils/hooks/useAmplitude';
import { useTranslation } from 'react-i18next';

export type LecturerFilterFormFields = {
  lecturer: string;
};

export type LecturerFilterProps = {
  hideFilter: Function;
  addFilterQueryToHistory: (
    itemData: Omit<ScheduleFilterHistoryItem, 'id'>,
  ) => void;
};

export const LecturerScheduleFilterQuery = gql`
  query LecturerScheduleFilter {
    listUsers {
      _id
      fullName
      role
      email
    }
  }
`;

const LecturerFilter: React.FC<LecturerFilterProps> = ({
  hideFilter,
  addFilterQueryToHistory,
}) => {
  const { t } = useTranslation();

  const { sendEvent } = useAmplitude();
  const [searchParams, setSearchParams] = useSearchParams();

  const { control, reset, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      lecturer: '',
    },
  });

  const {
    data: lectureScheduleFilterData,
    loading: lectureScheduleFilterLoading,
  } = useLecturerScheduleFilterQuery();

  const lecturer = watch('lecturer');

  const onSubmit = (data: LecturerFilterFormFields) => {
    hideFilter();
    setSearchParams(data);
    const lecturerData = lectureScheduleFilterData?.listUsers.find(
      user => user._id === data.lecturer,
    );

    if (lecturerData) {
      addFilterQueryToHistory({
        label: truncateFullName(lecturerData.fullName),
        filterParams: {
          lecturer: lecturerData._id,
        },
      });
    }

    sendEvent(AmplitudeEvent.ExecuteScheduleFilter, {
      type: 'lecturer',
      by: 'filterButton',
    });
  };

  useEffect(() => {
    const filterFields: Record<string, string> = {};
    for (const [key, value] of searchParams.entries()) {
      filterFields[key] = value;
    }
    reset(filterFields);
  }, []);

  const clearFilterFields = () => {
    setSearchParams({});
    reset({
      lecturer: '',
    });
  };

  const listLecturers = useMemo(
    () =>
      lectureScheduleFilterData?.listUsers
        .filter(({ role }) => role.includes('Lecturer'))
        .sort((lecturer1, lecturer2) =>
          lecturer1.fullName.localeCompare(lecturer2.fullName),
        ),
    [lectureScheduleFilterData?.listUsers],
  );

  return (
    <>
      <ClearFilterButton clearFilter={clearFilterFields} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack
          direction="column"
          alignItems="center"
          spacing={5}
          sx={{ mt: 14 }}
        >
          <Controller
            name="lecturer"
            control={control}
            render={({ field }) => (
              <>
                <Autocomplete
                  disablePortal
                  options={listLecturers ?? []}
                  {...field}
                  getOptionLabel={option =>
                    `${option.fullName}(${option.email})`
                  }
                  loading={lectureScheduleFilterLoading}
                  disabled={lectureScheduleFilterLoading}
                  onChange={(e, data) => field.onChange(data?._id ?? null)}
                  value={
                    listLecturers?.find(({ _id }) => field.value === _id) ??
                    null
                  }
                  fullWidth
                  renderInput={params => (
                    <TextField
                      {...params}
                      label={t('FILTER.LECTURER.PLACEHOLDER')}
                    />
                  )}
                />
              </>
            )}
          />
          {lecturer && (
            <Button type="submit" size="large">
              {t('FILTER.TO.SCHEDULE')} 🚀
            </Button>
          )}
        </Stack>
      </form>
    </>
  );
};

export default LecturerFilter;
