import { useCurrentUserContext } from 'context/currentUserContext';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import LoginForm from '../components/LoginForm';

const LoginPage = () => {
  const data = useCurrentUserContext();
  const navigate = useNavigate();

  if (data) {
    navigate('/');
  }

  return (
    <div className="w-screen h-screen flex items-center justify-center">
      <LoginForm />
    </div>
  );
};

export default LoginPage;
