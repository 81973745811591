import React, { useState } from 'react';
import ScheduleFilter from '../components/ScheduleFilter';
import Header from '../../../components/Header';
import { BellIcon } from '@heroicons/react/outline';
import ScheduleForGroup from '../components/ScheduleForGroup';
import ScheduleFilterHistory from '../components/ScheduleFilterHistory';
import ThemeSwitcher from 'components/ThemeSwitcher';
import { useTheme } from '@mui/system';
import LanguageSwitcher from 'components/LanguageSwitcher';
import LogoutButton from 'components/LogoutButton';

const SchedulePage = () => {
  const {
    palette: { mode },
  } = useTheme();

  return (
    <div
      className={`min-h-full ${
        mode === 'dark' ? 'bg-neutral-900 text-white' : 'bg-blue-100'
      } flex flex-col`}
    >
      <Header
        label="Розклад"
        rightWidget={
          <>
            <LanguageSwitcher />
            <ThemeSwitcher />
            <LogoutButton />
          </>
        }
        bgColor="bg-blue-100"
        bgDarkModeColor="bg-neutral-900"
      />
      <div className="p-4 pb-[93px] flex flex-col h-full flex-grow">
        <ScheduleFilterHistory />
        <ScheduleFilter />
        <ScheduleForGroup />
      </div>
    </div>
  );
};

export default SchedulePage;
