import React, { FC } from 'react';
import GoogleMeetIcon from '../assets/icons/GoogleMeetIcon';
import ZoomIcon from '../assets/icons/ZoomIcon';

const urlIconSetup = [
  {
    key: 'meet.google',
    icon: GoogleMeetIcon,
  },
  {
    key: 'zoom.us',
    icon: ZoomIcon,
  },
];

type LinkIconProviderProps = {
  className?: string;
  url: string;
};

const LinkIconProvider: FC<LinkIconProviderProps> = ({ className, url }) => {
  const Icon = urlIconSetup.find(({ key }) => url.includes(key))?.icon;

  if (!Icon) {
    return null;
  }

  return <Icon className={className} />;
};

export default LinkIconProvider;
