import { MoonIcon, SunIcon } from '@heroicons/react/outline';
import { IconButton, useTheme } from '@mui/material';
import { useToggleColorModeContext } from 'context/colorModeContext';
import React from 'react';

const ThemeSwitcher = () => {
  const {
    palette: { mode },
  } = useTheme();

  const { toggleColorMode } = useToggleColorModeContext();

  return mode === 'light' ? (
    <MoonIcon
      onClick={toggleColorMode}
      className={`w-7 h-7 cursor-pointer ${
        mode === 'light' ? 'text-black' : 'dark:text-white'
      } `}
    />
  ) : (
    <SunIcon
      onClick={toggleColorMode}
      className={`w-7 h-7 cursor-pointer ${
        mode === 'dark' ? 'dark:text-white' : 'text-black'
      } `}
    />
  );
};

export default ThemeSwitcher;
