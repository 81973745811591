import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Faculty, Group, ScheduleForWeek, Specialty } from './types';

export const mainApi = createApi({
  reducerPath: 'mainApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://sheltered-waters-60703.herokuapp.com/api/',
  }),
  endpoints: builder => ({
    getScheduleForWeek: builder.query<
      ScheduleForWeek,
      { lecturer?: string; group?: string }
    >({
      query: ({ lecturer, group }) => ({
        url: `schedule/getScheduleForWeek`,
        params: { lecturer, group },
      }),
    }),
    getAllGroups: builder.query<Group[], void>({
      query: () => ({
        url: `groups`,
      }),
    }),
    getGroupById: builder.query<Group, { id: string }>({
      query: ({ id }) => ({
        url: `groups/${id}`,
      }),
    }),
    getAllFaculties: builder.query<Faculty[], void>({
      query: () => ({
        url: `faculties`,
      }),
    }),
    getFacultyById: builder.query<Faculty, { id: string }>({
      query: ({ id }) => ({
        url: `faculties/${id}`,
      }),
    }),
    getAllSpecialties: builder.query<Specialty[], void>({
      query: () => ({
        url: `specialties`,
      }),
    }),
    getSpecialtyById: builder.query<Specialty, { id: string }>({
      query: ({ id }) => ({
        url: `specialties/${id}`,
      }),
    }),
  }),
});

export const {
  useGetScheduleForWeekQuery,
  useGetAllGroupsQuery,
  useGetGroupByIdQuery,
  useGetFacultyByIdQuery,
  useGetAllFacultiesQuery,
  useGetAllSpecialtiesQuery,
  useGetSpecialtyByIdQuery,
} = mainApi;
