import { gql } from '@apollo/client';
import { GoogleOAuthProvider } from '@react-oauth/google';
import IsAuthedRoute from 'components/IsAuthedRoute';
import { CurrentUserProvider } from 'context/currentUserContext';
import { useAppQuery } from 'generated/graphql';
import LoginPage from 'modules/auth/pages/LoginPage';
import LogoutPage from 'modules/auth/pages/LogoutPage';
import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import PageLayout from './components/PageLayout';
import HomePage from './modules/home/pages/HomePage';
import SchedulePage from './modules/schedule/pages/SchedulePage';
import { useInitApp } from './utils/hooks/useInitApp';

export const AppQuery = gql`
  ${CurrentUserProvider.fragments.me}

  query App {
    me {
      ...CurrentUserProvider_User
    }
  }
`;

const App = () => {
  const { loading, data } = useAppQuery();

  const navigate = useNavigate();

  useEffect(() => {
    if (!loading && !data) {
      navigate('/login');
      return;
    }
  }, [loading, data]);

  useInitApp();

  if (loading) {
    return null;
  }

  return (
    <GoogleOAuthProvider clientId="281564092522-9pprohqvucr4hsnq4d41pa18s18j53hd.apps.googleusercontent.com">
      <CurrentUserProvider value={data?.me ?? null}>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/logout" element={<LogoutPage />}></Route>
          <Route path="/" element={<PageLayout />}>
            <Route path="" element={<HomePage />}></Route>
            <Route path="schedule" element={<SchedulePage />}></Route>
            <Route path="*" element={<Navigate to={'/'} />}></Route>
          </Route>
        </Routes>
      </CurrentUserProvider>
    </GoogleOAuthProvider>
  );
};

export default App;
