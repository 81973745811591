import { FC, useState } from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/outline';
import BottomSheet from 'components/BottomSheet';
import { useTranslation } from 'react-i18next';

type ScheduleNote = {
  note: string;
};

const ScheduleNote: FC<ScheduleNote> = ({ note }) => {
  const { t } = useTranslation();
  const [isNoteVisible, setIsNoteVisible] = useState(false);

  return (
    <>
      <ExclamationCircleIcon
        onClick={() => setIsNoteVisible(true)}
        className="w-6 h-6 cursor-pointer"
        data-testid="note-button"
      />
      <BottomSheet
        isOpen={isNoteVisible}
        handleClose={() => setIsNoteVisible(false)}
        handleOpen={() => setIsNoteVisible(true)}
        minHeight={200}
      >
        <div className="text-center mt-2">
          {t('NOTE')}: {note}
        </div>
      </BottomSheet>
    </>
  );
};

export default ScheduleNote;
