import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
};

export type AuthResult = {
  __typename?: 'AuthResult';
  token: Scalars['String'];
  user: User;
};

export type AuthViaGoogleInput = {
  googleAccessToken: Scalars['String'];
};

export type Building = {
  __typename?: 'Building';
  _id: Scalars['String'];
  classrooms: Array<Classroom>;
  floors: Array<Scalars['Int']>;
  name: Scalars['String'];
};

export type Class = {
  __typename?: 'Class';
  _id: Scalars['String'];
  classroom?: Maybe<Classroom>;
  day: Scalars['Int'];
  group: Array<Group>;
  lecturer?: Maybe<User>;
  link?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  sequence: Scalars['Int'];
  title: Scalars['String'];
  type: Scalars['String'];
  variant: Scalars['String'];
};

export type Classroom = {
  __typename?: 'Classroom';
  _id: Scalars['String'];
  building: Building;
  capacity?: Maybe<Scalars['Int']>;
  classes: Array<Class>;
  floorNumber: Scalars['Int'];
  hasComputer?: Maybe<Scalars['Boolean']>;
  hasProjector?: Maybe<Scalars['Boolean']>;
  slug: Scalars['String'];
  type: Array<Scalars['String']>;
};

export type CloneClassInput = {
  classToCloneId: Scalars['String'];
  day: Scalars['Int'];
  group: Array<Scalars['String']>;
  sequence: Scalars['Int'];
  variant: Scalars['String'];
};

export type CreateBuildingInput = {
  name: Scalars['String'];
};

export type CreateClassInput = {
  classroom?: InputMaybe<Scalars['String']>;
  day: Scalars['Int'];
  faculty: Scalars['String'];
  group: Array<Scalars['String']>;
  lecturer?: InputMaybe<Scalars['String']>;
  link?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  sequence: Scalars['Int'];
  title: Scalars['String'];
  type: Scalars['String'];
  variant: Scalars['String'];
};

export type CreateClassroomInput = {
  building: Scalars['String'];
  capacity?: InputMaybe<Scalars['Int']>;
  floorNumber: Scalars['Int'];
  hasComputer?: InputMaybe<Scalars['Boolean']>;
  hasProjector?: InputMaybe<Scalars['Boolean']>;
  slug: Scalars['String'];
  type: Array<Scalars['String']>;
};

export type CreateFacultyInput = {
  fullTitle: Scalars['String'];
  shortTitle: Scalars['String'];
};

export type CreateGroupInput = {
  course: Scalars['String'];
  slug: Scalars['String'];
  specialty: Scalars['String'];
};

export type CreateSpecialtyInput = {
  code: Scalars['String'];
  courses: Array<Scalars['String']>;
  faculty: Scalars['String'];
  title: Scalars['String'];
};

export type CreateUserInput = {
  avatarUrl?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  facultiesScopes?: InputMaybe<Array<Scalars['String']>>;
  fullName: Scalars['String'];
  role: Array<Scalars['String']>;
};

export type DeleteBuildingInput = {
  _id: Scalars['String'];
};

export type DeleteClassInput = {
  _id: Scalars['String'];
};

export type DeleteClassroomInput = {
  _id: Scalars['String'];
};

export type DeleteFacultyInput = {
  _id: Scalars['String'];
};

export type DeleteGroupInput = {
  _id: Scalars['String'];
};

export type DeleteSpecialtyInput = {
  _id: Scalars['String'];
};

export type DeleteUserInput = {
  _id: Scalars['String'];
};

export type Faculty = {
  __typename?: 'Faculty';
  _id: Scalars['String'];
  fullTitle: Scalars['String'];
  shortTitle: Scalars['String'];
  specialties: Array<Specialty>;
};

export type GetClassTitleAutocompleteResult = {
  __typename?: 'GetClassTitleAutocompleteResult';
  autocomplete: Array<Scalars['String']>;
};

export type GetListClassroomsFilterInput = {
  building?: InputMaybe<Scalars['String']>;
  capacityRange?: InputMaybe<GetListClassroomsFilterRangeInput>;
  floorNumber?: InputMaybe<Scalars['Int']>;
  hasComputer?: InputMaybe<Scalars['Boolean']>;
  hasProjector?: InputMaybe<Scalars['Boolean']>;
  pairs: Array<InputMaybe<Scalars['Int']>>;
  type?: InputMaybe<Scalars['String']>;
};

export type GetListClassroomsFilterRangeInput = {
  max?: InputMaybe<Scalars['Int']>;
  min?: InputMaybe<Scalars['Int']>;
};

export type GoogleProfileData = {
  __typename?: 'GoogleProfileData';
  avatarUrl?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
};

export type Group = {
  __typename?: 'Group';
  _id: Scalars['String'];
  classes: Array<Class>;
  course: Scalars['String'];
  slug: Scalars['String'];
  specialty: Specialty;
};

export type ListClassFilterInput = {
  day?: InputMaybe<Scalars['String']>;
  sequence?: InputMaybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  _?: Maybe<Scalars['Boolean']>;
  authViaGoogle: AuthResult;
  cloneClass: Class;
  createBuilding: Building;
  createClass: Class;
  createClassroom: Classroom;
  createFaculty: Faculty;
  createGroup: Group;
  createSpecialty: Specialty;
  createUser: User;
  deleteBuilding: Building;
  deleteClass: Class;
  deleteClassroom: Classroom;
  deleteFaculty: Faculty;
  deleteGroup: Group;
  deleteSpecialty: Specialty;
  deleteUser: User;
  updateBuilding: Building;
  updateClass: Class;
  updateClassroom: Classroom;
  updateFaculty: Faculty;
  updateGroup: Group;
  updateSpecialty: Specialty;
  updateUser: User;
};


export type MutationAuthViaGoogleArgs = {
  input: AuthViaGoogleInput;
};


export type MutationCloneClassArgs = {
  input: CloneClassInput;
};


export type MutationCreateBuildingArgs = {
  input: CreateBuildingInput;
};


export type MutationCreateClassArgs = {
  input: CreateClassInput;
};


export type MutationCreateClassroomArgs = {
  input: CreateClassroomInput;
};


export type MutationCreateFacultyArgs = {
  input: CreateFacultyInput;
};


export type MutationCreateGroupArgs = {
  input: CreateGroupInput;
};


export type MutationCreateSpecialtyArgs = {
  input: CreateSpecialtyInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationDeleteBuildingArgs = {
  input: DeleteBuildingInput;
};


export type MutationDeleteClassArgs = {
  input: DeleteClassInput;
};


export type MutationDeleteClassroomArgs = {
  input: DeleteClassroomInput;
};


export type MutationDeleteFacultyArgs = {
  input: DeleteFacultyInput;
};


export type MutationDeleteGroupArgs = {
  input: DeleteGroupInput;
};


export type MutationDeleteSpecialtyArgs = {
  input: DeleteSpecialtyInput;
};


export type MutationDeleteUserArgs = {
  input: DeleteUserInput;
};


export type MutationUpdateBuildingArgs = {
  input: UpdateBuildingInput;
};


export type MutationUpdateClassArgs = {
  input: UpdateClassInput;
};


export type MutationUpdateClassroomArgs = {
  input: UpdateClassroomInput;
};


export type MutationUpdateFacultyArgs = {
  input: UpdateFacultyInput;
};


export type MutationUpdateGroupArgs = {
  input: UpdateGroupInput;
};


export type MutationUpdateSpecialtyArgs = {
  input: UpdateSpecialtyInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type Query = {
  __typename?: 'Query';
  _?: Maybe<Scalars['Boolean']>;
  getBuilding: Building;
  getClass: Class;
  getClassTitleAutocomplete: GetClassTitleAutocompleteResult;
  getClassroom: Classroom;
  getFaculty: Faculty;
  getGroup: Group;
  getSpecialty: Specialty;
  getUser: User;
  listBuildings: Array<Building>;
  listClasses: Array<Class>;
  listClassrooms: Array<Classroom>;
  listFaculties: Array<Faculty>;
  listGroups: Array<Group>;
  listSpecialties: Array<Specialty>;
  listUsers: Array<User>;
  me: User;
};


export type QueryGetBuildingArgs = {
  _id: Scalars['String'];
};


export type QueryGetClassArgs = {
  _id: Scalars['String'];
};


export type QueryGetClassTitleAutocompleteArgs = {
  search: Scalars['String'];
};


export type QueryGetClassroomArgs = {
  _id: Scalars['String'];
};


export type QueryGetFacultyArgs = {
  _id: Scalars['String'];
};


export type QueryGetGroupArgs = {
  _id: Scalars['String'];
};


export type QueryGetSpecialtyArgs = {
  _id: Scalars['String'];
};


export type QueryGetUserArgs = {
  _id: Scalars['String'];
};


export type QueryListClassesArgs = {
  filter?: InputMaybe<ListClassFilterInput>;
};


export type QueryListClassroomsArgs = {
  building?: InputMaybe<Scalars['String']>;
  capacityRangeMax?: InputMaybe<Scalars['Int']>;
  capacityRangeMin?: InputMaybe<Scalars['Int']>;
  filterType?: InputMaybe<Scalars['String']>;
  floorNumber?: InputMaybe<Scalars['Int']>;
  hasComputer?: InputMaybe<Scalars['Boolean']>;
  hasProjector?: InputMaybe<Scalars['Boolean']>;
  pairs?: InputMaybe<Array<Scalars['Int']>>;
  type?: InputMaybe<Scalars['String']>;
  variant?: InputMaybe<Scalars['String']>;
  weekDays?: InputMaybe<Array<Scalars['Int']>>;
};

export type Specialty = {
  __typename?: 'Specialty';
  _id: Scalars['String'];
  code: Scalars['String'];
  courses: Array<Scalars['String']>;
  faculty: Faculty;
  groups: Array<Group>;
  title: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  _?: Maybe<Scalars['Boolean']>;
};

export type UpdateBuildingInput = {
  _id: Scalars['String'];
  classrooms?: InputMaybe<Array<Scalars['String']>>;
  floors?: InputMaybe<Array<Scalars['Int']>>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateClassInput = {
  _id: Scalars['String'];
  classroom?: InputMaybe<Scalars['String']>;
  day?: InputMaybe<Scalars['Int']>;
  group?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  lecturer?: InputMaybe<Scalars['String']>;
  link?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  sequence?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  variant?: InputMaybe<Scalars['String']>;
};

export type UpdateClassroomInput = {
  _id: Scalars['String'];
  capacity?: InputMaybe<Scalars['Int']>;
  hasComputer?: InputMaybe<Scalars['Boolean']>;
  hasProjector?: InputMaybe<Scalars['Boolean']>;
  slug?: InputMaybe<Scalars['String']>;
  type: Array<Scalars['String']>;
};

export type UpdateFacultyInput = {
  _id: Scalars['String'];
  fullTitle?: InputMaybe<Scalars['String']>;
  shortTitle?: InputMaybe<Scalars['String']>;
  specialties?: InputMaybe<Array<Scalars['String']>>;
};

export type UpdateGroupInput = {
  _id: Scalars['String'];
  slug?: InputMaybe<Scalars['String']>;
};

export type UpdateSpecialtyInput = {
  _id: Scalars['String'];
  code?: InputMaybe<Scalars['String']>;
  courses: Array<Scalars['String']>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateUserInput = {
  _id: Scalars['String'];
  avatarUrl?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  facultiesScopes?: InputMaybe<Array<Scalars['String']>>;
  fullName?: InputMaybe<Scalars['String']>;
  role: Array<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  _id: Scalars['String'];
  avatarUrl?: Maybe<Scalars['String']>;
  classes: Array<Class>;
  email: Scalars['String'];
  facultiesScopes: Array<Faculty>;
  fullName: Scalars['String'];
  googleProfileData?: Maybe<GoogleProfileData>;
  permissions?: Maybe<Array<Scalars['String']>>;
  role: Array<Scalars['String']>;
};

export type Adsdas = {
  asda: Scalars['String'];
};

export type AppQueryVariables = Exact<{ [key: string]: never; }>;


export type AppQuery = { __typename?: 'Query', me: { __typename?: 'User', _id: string, fullName: string, avatarUrl?: string | null | undefined, role: Array<string>, facultiesScopes: Array<{ __typename?: 'Faculty', _id: string, fullTitle: string, shortTitle: string }> } };

export type CurrentUserProvider_UserFragment = { __typename?: 'User', _id: string, fullName: string, avatarUrl?: string | null | undefined, role: Array<string>, facultiesScopes: Array<{ __typename?: 'Faculty', _id: string, fullTitle: string, shortTitle: string }> };

export type AuthViaGoogleMutationVariables = Exact<{
  input: AuthViaGoogleInput;
}>;


export type AuthViaGoogleMutation = { __typename?: 'Mutation', authViaGoogle: { __typename?: 'AuthResult', token: string, user: { __typename?: 'User', _id: string, fullName: string, role: Array<string>, permissions?: Array<string> | null | undefined } } };

export type LecturerScheduleFilterQueryVariables = Exact<{ [key: string]: never; }>;


export type LecturerScheduleFilterQuery = { __typename?: 'Query', listUsers: Array<{ __typename?: 'User', _id: string, fullName: string, role: Array<string>, email: string }> };

export type StudentScheduleFilterQueryVariables = Exact<{ [key: string]: never; }>;


export type StudentScheduleFilterQuery = { __typename?: 'Query', listFaculties: Array<{ __typename?: 'Faculty', _id: string, fullTitle: string, specialties: Array<{ __typename?: 'Specialty', _id: string, title: string, groups: Array<{ __typename?: 'Group', _id: string, slug: string, course: string }> }> }> };

export type ScheduleForStudentQueryVariables = Exact<{
  _id: Scalars['String'];
}>;


export type ScheduleForStudentQuery = { __typename?: 'Query', getGroup: { __typename?: 'Group', _id: string, slug: string, course: string, specialty: { __typename?: 'Specialty', _id: string, title: string }, classes: Array<{ __typename?: 'Class', _id: string, title: string, sequence: number, day: number, variant: string, link?: string | null | undefined, note?: string | null | undefined, type: string, classroom?: { __typename?: 'Classroom', _id: string, slug: string } | null | undefined, lecturer?: { __typename?: 'User', _id: string, fullName: string, avatarUrl?: string | null | undefined, email: string } | null | undefined, group: Array<{ __typename?: 'Group', _id: string, slug: string, course: string, specialty: { __typename?: 'Specialty', _id: string, title: string } }> }> } };

export type ScheduleForLecturerQueryVariables = Exact<{
  _id: Scalars['String'];
}>;


export type ScheduleForLecturerQuery = { __typename?: 'Query', getUser: { __typename?: 'User', _id: string, fullName: string, avatarUrl?: string | null | undefined, email: string, classes: Array<{ __typename?: 'Class', _id: string, title: string, sequence: number, link?: string | null | undefined, day: number, variant: string, note?: string | null | undefined, type: string, classroom?: { __typename?: 'Classroom', _id: string, slug: string } | null | undefined, lecturer?: { __typename?: 'User', _id: string, fullName: string, avatarUrl?: string | null | undefined, email: string } | null | undefined, group: Array<{ __typename?: 'Group', _id: string, slug: string, course: string, specialty: { __typename?: 'Specialty', _id: string, title: string } }> }> } };

export const CurrentUserProvider_UserFragmentDoc = gql`
    fragment CurrentUserProvider_User on User {
  _id
  fullName
  avatarUrl
  role
  facultiesScopes {
    _id
    fullTitle
    shortTitle
  }
}
    `;
export const AppDocument = gql`
    query App {
  me {
    ...CurrentUserProvider_User
  }
}
    ${CurrentUserProvider_UserFragmentDoc}`;

/**
 * __useAppQuery__
 *
 * To run a query within a React component, call `useAppQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppQuery({
 *   variables: {
 *   },
 * });
 */
export function useAppQuery(baseOptions?: Apollo.QueryHookOptions<AppQuery, AppQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AppQuery, AppQueryVariables>(AppDocument, options);
      }
export function useAppLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppQuery, AppQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AppQuery, AppQueryVariables>(AppDocument, options);
        }
export type AppQueryHookResult = ReturnType<typeof useAppQuery>;
export type AppLazyQueryHookResult = ReturnType<typeof useAppLazyQuery>;
export type AppQueryResult = Apollo.QueryResult<AppQuery, AppQueryVariables>;
export const AuthViaGoogleDocument = gql`
    mutation authViaGoogle($input: AuthViaGoogleInput!) {
  authViaGoogle(input: $input) {
    token
    user {
      _id
      fullName
      role
      permissions
    }
  }
}
    `;
export type AuthViaGoogleMutationFn = Apollo.MutationFunction<AuthViaGoogleMutation, AuthViaGoogleMutationVariables>;

/**
 * __useAuthViaGoogleMutation__
 *
 * To run a mutation, you first call `useAuthViaGoogleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthViaGoogleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authViaGoogleMutation, { data, loading, error }] = useAuthViaGoogleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAuthViaGoogleMutation(baseOptions?: Apollo.MutationHookOptions<AuthViaGoogleMutation, AuthViaGoogleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AuthViaGoogleMutation, AuthViaGoogleMutationVariables>(AuthViaGoogleDocument, options);
      }
export type AuthViaGoogleMutationHookResult = ReturnType<typeof useAuthViaGoogleMutation>;
export type AuthViaGoogleMutationResult = Apollo.MutationResult<AuthViaGoogleMutation>;
export type AuthViaGoogleMutationOptions = Apollo.BaseMutationOptions<AuthViaGoogleMutation, AuthViaGoogleMutationVariables>;
export const LecturerScheduleFilterDocument = gql`
    query LecturerScheduleFilter {
  listUsers {
    _id
    fullName
    role
    email
  }
}
    `;

/**
 * __useLecturerScheduleFilterQuery__
 *
 * To run a query within a React component, call `useLecturerScheduleFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useLecturerScheduleFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLecturerScheduleFilterQuery({
 *   variables: {
 *   },
 * });
 */
export function useLecturerScheduleFilterQuery(baseOptions?: Apollo.QueryHookOptions<LecturerScheduleFilterQuery, LecturerScheduleFilterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LecturerScheduleFilterQuery, LecturerScheduleFilterQueryVariables>(LecturerScheduleFilterDocument, options);
      }
export function useLecturerScheduleFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LecturerScheduleFilterQuery, LecturerScheduleFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LecturerScheduleFilterQuery, LecturerScheduleFilterQueryVariables>(LecturerScheduleFilterDocument, options);
        }
export type LecturerScheduleFilterQueryHookResult = ReturnType<typeof useLecturerScheduleFilterQuery>;
export type LecturerScheduleFilterLazyQueryHookResult = ReturnType<typeof useLecturerScheduleFilterLazyQuery>;
export type LecturerScheduleFilterQueryResult = Apollo.QueryResult<LecturerScheduleFilterQuery, LecturerScheduleFilterQueryVariables>;
export const StudentScheduleFilterDocument = gql`
    query StudentScheduleFilter {
  listFaculties {
    _id
    fullTitle
    specialties {
      _id
      title
      groups {
        _id
        slug
        course
      }
    }
  }
}
    `;

/**
 * __useStudentScheduleFilterQuery__
 *
 * To run a query within a React component, call `useStudentScheduleFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentScheduleFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentScheduleFilterQuery({
 *   variables: {
 *   },
 * });
 */
export function useStudentScheduleFilterQuery(baseOptions?: Apollo.QueryHookOptions<StudentScheduleFilterQuery, StudentScheduleFilterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StudentScheduleFilterQuery, StudentScheduleFilterQueryVariables>(StudentScheduleFilterDocument, options);
      }
export function useStudentScheduleFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StudentScheduleFilterQuery, StudentScheduleFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StudentScheduleFilterQuery, StudentScheduleFilterQueryVariables>(StudentScheduleFilterDocument, options);
        }
export type StudentScheduleFilterQueryHookResult = ReturnType<typeof useStudentScheduleFilterQuery>;
export type StudentScheduleFilterLazyQueryHookResult = ReturnType<typeof useStudentScheduleFilterLazyQuery>;
export type StudentScheduleFilterQueryResult = Apollo.QueryResult<StudentScheduleFilterQuery, StudentScheduleFilterQueryVariables>;
export const ScheduleForStudentDocument = gql`
    query ScheduleForStudent($_id: String!) {
  getGroup(_id: $_id) {
    _id
    slug
    course
    specialty {
      _id
      title
    }
    classes {
      _id
      title
      classroom {
        _id
        slug
      }
      lecturer {
        _id
        fullName
        avatarUrl
        email
      }
      sequence
      day
      variant
      link
      note
      group {
        _id
        slug
        course
        specialty {
          _id
          title
        }
      }
      type
    }
  }
}
    `;

/**
 * __useScheduleForStudentQuery__
 *
 * To run a query within a React component, call `useScheduleForStudentQuery` and pass it any options that fit your needs.
 * When your component renders, `useScheduleForStudentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScheduleForStudentQuery({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useScheduleForStudentQuery(baseOptions: Apollo.QueryHookOptions<ScheduleForStudentQuery, ScheduleForStudentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ScheduleForStudentQuery, ScheduleForStudentQueryVariables>(ScheduleForStudentDocument, options);
      }
export function useScheduleForStudentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ScheduleForStudentQuery, ScheduleForStudentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ScheduleForStudentQuery, ScheduleForStudentQueryVariables>(ScheduleForStudentDocument, options);
        }
export type ScheduleForStudentQueryHookResult = ReturnType<typeof useScheduleForStudentQuery>;
export type ScheduleForStudentLazyQueryHookResult = ReturnType<typeof useScheduleForStudentLazyQuery>;
export type ScheduleForStudentQueryResult = Apollo.QueryResult<ScheduleForStudentQuery, ScheduleForStudentQueryVariables>;
export const ScheduleForLecturerDocument = gql`
    query ScheduleForLecturer($_id: String!) {
  getUser(_id: $_id) {
    _id
    fullName
    avatarUrl
    email
    classes {
      _id
      title
      classroom {
        _id
        slug
      }
      lecturer {
        _id
        fullName
        avatarUrl
        email
      }
      sequence
      link
      day
      variant
      note
      group {
        _id
        slug
        course
        specialty {
          _id
          title
        }
      }
      type
    }
  }
}
    `;

/**
 * __useScheduleForLecturerQuery__
 *
 * To run a query within a React component, call `useScheduleForLecturerQuery` and pass it any options that fit your needs.
 * When your component renders, `useScheduleForLecturerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScheduleForLecturerQuery({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useScheduleForLecturerQuery(baseOptions: Apollo.QueryHookOptions<ScheduleForLecturerQuery, ScheduleForLecturerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ScheduleForLecturerQuery, ScheduleForLecturerQueryVariables>(ScheduleForLecturerDocument, options);
      }
export function useScheduleForLecturerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ScheduleForLecturerQuery, ScheduleForLecturerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ScheduleForLecturerQuery, ScheduleForLecturerQueryVariables>(ScheduleForLecturerDocument, options);
        }
export type ScheduleForLecturerQueryHookResult = ReturnType<typeof useScheduleForLecturerQuery>;
export type ScheduleForLecturerLazyQueryHookResult = ReturnType<typeof useScheduleForLecturerLazyQuery>;
export type ScheduleForLecturerQueryResult = Apollo.QueryResult<ScheduleForLecturerQuery, ScheduleForLecturerQueryVariables>;