import { Callback, LogReturn } from 'amplitude-js';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAmplitudeInstance,
  setAmplitudeInstance,
} from '../../store/slices/settings/slice';

const AMPLITUDE_KEY = 'ca69274825d3a0eac9ec49648be30c1c';

type SendEvent = (
  event: AmplitudeEvent,
  data?: any,
  callback?: Callback,
  errorCallback?: Callback,
  outOfSession?: boolean,
) => LogReturn | undefined;

export const useAmplitude = () => {
  const amplitudeInstance = useSelector(getAmplitudeInstance);

  const sendEvent = useCallback<SendEvent>(
    (...args) => {
      if (amplitudeInstance && process.env.NODE_ENV === 'production') {
        return amplitudeInstance.logEvent(...args);
      }
    },
    [amplitudeInstance],
  );

  return {
    sendEvent,
  };
};

export const useInitAmplitude = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const instance = window?.amplitude?.getInstance();

    if (instance) {
      instance.init(AMPLITUDE_KEY, undefined, undefined, instance => {
        dispatch(setAmplitudeInstance(instance));
      });
    }
  }, [dispatch]);
};

export enum AmplitudeEvent {
  Test = 'Test',
  OpenApp = 'OpenApp',
  ExecuteScheduleFilter = 'ExecuteScheduleFilter',
  HomePageGuideScheduleLinkClicked = 'HomePageGuideScheduleLinkClicked',
  ScheduleGenerateGRCodeBtnClicked = 'ScheduleGenerateGRCodeBtnClicked',
  OpenNotifications = 'OpenNotifications',
  OpenSchedulePage = 'OpenSchedulePage',
  OpenPollPage = 'OpenPollPage',
  ScanQRClicked = 'ScanQRClicked',
  NoobTryToOpenInactiveMenu = 'NoobTryToOpenInactiveMenu',
  PollPageFeedbackBtnClicked = 'PollPageFeedbackBtnClicked',
}
