import { Skeleton, useTheme } from '@mui/material';
import React from 'react';

const ScheduleForDaySkeleton = () => {
  const {
    palette: { mode },
  } = useTheme();

  return (
    <div>
      <div className="px-4 flex justify-between">
        <Skeleton width={150} height={50} />
        <Skeleton width={50} height={50} variant="rectangular" />
      </div>
      {[1, 2, 3].map((_, idx) => (
        <div
          key={idx}
          className={`rounded-md mt-5 my-2 mx-auto py-4 px-4 ${
            mode === 'dark' ? 'bg-black' : 'bg-white'
          } `}
        >
          {[1, 2, 3].map((_, idx) => (
            <Skeleton key={idx} height={35} />
          ))}
        </div>
      ))}
    </div>
  );
};

export default ScheduleForDaySkeleton;
