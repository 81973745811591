import { Outlet } from 'react-router-dom';
import Menu from './Menu';

const PageLayout = () => {
  return (
    <>
      <Outlet />
      <Menu />
    </>
  );
};

export default PageLayout;
