import React from 'react';
import { useTranslation } from 'react-i18next';
import UAFlag from '../assets/images/ua-flag.png';
import ENFlag from '../assets/images/eng-flag.png';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const switchToUa = () => {
    i18n.changeLanguage('ua');
    localStorage.setItem('lng', 'ua');
  };

  const switchToEn = () => {
    i18n.changeLanguage('en');
    localStorage.setItem('lng', 'en');
  };

  const handleClick = () =>
    i18n.language === 'ua' ? switchToEn() : switchToUa();

  return i18n.language === 'ua' ? (
    <img
      className="w-8 h-6 rounded-sm cursor-pointer"
      onClick={handleClick}
      src={UAFlag}
      alt="flag"
    />
  ) : (
    <img
      className="w-8 h-6 rounded-sm cursor-pointer"
      onClick={handleClick}
      src={ENFlag}
      alt="flag"
    />
  );
};

export default LanguageSwitcher;
