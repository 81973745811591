import {
  HomeIcon,
  TableIcon,
  MenuAlt1Icon,
  AnnotationIcon,
} from '@heroicons/react/outline';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import QRCodeModal from './QRCodeModal';
import { AmplitudeEvent, useAmplitude } from '../utils/hooks/useAmplitude';
import { useTheme } from '@mui/system';
import { useTranslation } from 'react-i18next';

const Menu = () => {
  const { t } = useTranslation();

  const { sendEvent } = useAmplitude();
  const navigate = useNavigate();

  const {
    palette: { mode },
  } = useTheme();

  return (
    <div
      className={`bottom-menu fixed  ${
        mode === 'dark' ? 'bg-gray-900 text-white' : 'bg-slate-50'
      } shadow-lg bottom-0 left-0 right-0 border-t-2 z-50`}
    >
      <div className="relative px-4 py-3 flex flex-row justify-between">
        <div
          className={`cursor-pointer absolute translate-x-[-50%] p-3.5 bottom-6 ${
            mode === 'dark' ? 'bg-neutral-900 text-white' : 'bg-white'
          }  border rounded-full left-[50%]  shadow-[0px_0px_10px_0px_rgba(0,0,0,0.20)]`}
        >
          <QRCodeModal />
        </div>
        <div
          onClick={() => navigate('/')}
          className={classNames({
            'text-blue-500':
              window.location.href.split('/')[
                window.location.href.split('/').length - 1
              ] === '',
            'flex flex-col items-center cursor-pointer ': true,
          })}
        >
          <HomeIcon className="w-7 h-7" />
          <p className="text-xs">{t('HOME.TITLE')}</p>
        </div>
        <div
          onClick={() => {
            navigate('/schedule');
            sendEvent(AmplitudeEvent.OpenSchedulePage);
          }}
          className={classNames({
            'text-blue-500':
              window.location.href.split('/')[
                window.location.href.split('/').length - 1
              ] === 'schedule',
            'flex flex-col items-center cursor-pointer ': true,
          })}
        >
          <TableIcon className="w-7 h-7" />
          <p className="text-xs">{t('MENU.SCHEDULE')}</p>
        </div>
        <div className="w-[33px]"></div>
        <a
          onClick={() => sendEvent(AmplitudeEvent.OpenPollPage)}
          href="https://docs.google.com/forms/d/e/1FAIpQLSdWjGAfgc2oCZJAd_7u11vW2swruutSuIXTKmjDkciVF_ARBg/viewform"
          className="flex flex-col items-center cursor-pointer"
        >
          <AnnotationIcon className="w-7 h-7" />
          <div className="text-xs">{t('MENU.POLL')}</div>
        </a>
        <div
          className={classNames({
            'flex flex-col items-center cursor-not-allowed text-gray-500 ':
              true,
          })}
          onClick={() => {
            sendEvent(AmplitudeEvent.NoobTryToOpenInactiveMenu);
          }}
        >
          <MenuAlt1Icon className="w-7 h-7" />
          <p className="text-xs">{t('MENU')}</p>
        </div>
      </div>
    </div>
  );
};

export default Menu;
