import { Chip } from '@mui/material';
import LinkIconProvider from 'components/LinkIconProvider';
import { Class } from 'generated/graphql';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ClassVariantsEnum,
  ScheduleClassEnum,
  scheduleClassType,
  scheduleClassTypeColor,
  scheduleTimeConfig,
} from 'utils/schedule.utils';
import ClassGroupsInfo from '../ClassGroupsInfo';
import LecturerInfo from '../LecturerInfo';
import ScheduleNote from '../ScheduleNote';

export type VariantClassProps = {
  class: Class;
  onCodeClick: (code?: string) => void;
};

const VariantClass: FC<VariantClassProps> = ({
  class: { sequence, note, group, classroom, link, type, lecturer, title },
  onCodeClick,
}) => {
  const { t } = useTranslation();

  const isLecturerSchedule = window.location.href.includes('lecturer');

  return (
    <div className="w-full flex flex-col gap-1 p-2">
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          <div className="mr-2">{sequence}.</div>{' '}
          <div>{scheduleTimeConfig[sequence]}</div>
        </div>
        <div className="flex gap-1 items-center">
          {classroom?.slug}
          {!link?.includes('http') ? (
            <span className="cursor-pointer" onClick={() => onCodeClick(link!)}>
              {link}
            </span>
          ) : (
            <a href={link ?? ''}>
              <LinkIconProvider url={link ?? ''} className="w-6 h-6" />
            </a>
          )}
        </div>
      </div>
      <div className="w-3/4 flex gap-1 items-start flex-col">
        <div className="flex">
          <Chip
            color={scheduleClassTypeColor[type as ScheduleClassEnum]}
            label={t(scheduleClassType[type as ScheduleClassEnum])}
            size="small"
            className="font-bold"
          />
          <div className="flex gap-1 ml-2">
            {isLecturerSchedule && <ClassGroupsInfo groups={group} />}
            {group.length > 1 && !isLecturerSchedule && (
              <ClassGroupsInfo groups={group} />
            )}
            {!!note && <ScheduleNote note={note} />}
          </div>
        </div>
        <div>{title}</div>
      </div>
      {lecturer ? <LecturerInfo lecturer={lecturer} /> : null}
    </div>
  );
};

export default VariantClass;
