import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { resources } from './i18nMessages';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    lng: localStorage.getItem('lng') ? localStorage.getItem('lng')! : 'ua',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
