import { useCallback, useState } from 'react';

import {
  FilterIcon,
  UserGroupIcon,
  AcademicCapIcon,
} from '@heroicons/react/outline';
import StudentFilter from './StudentFilter';
import LecturerFilter from './LecturerFilter';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  addToFilterHistory,
  ScheduleFilterHistoryItem,
} from '../../../../store/slices/scheduleFilter/slice';
import { useTheme } from '@mui/system';
import { useTranslation } from 'react-i18next';
import BottomSheet from 'components/BottomSheet';

const ScheduleFilter = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [filterVisible, setFilterVisible] = useState(false);

  const [_, setSearchParams] = useSearchParams();

  const {
    palette: { mode },
  } = useTheme();

  const [isLecturer, setIsLecturer] = useState(false);

  const changeFilter = () => {
    setSearchParams({});
    setIsLecturer(!isLecturer);
  };

  const addFilterQueryToHistory = useCallback(
    (itemData: Omit<ScheduleFilterHistoryItem, 'id'>) => {
      dispatch(addToFilterHistory(itemData));
    },
    [],
  );

  return (
    <>
      <button
        onClick={() => setFilterVisible(true)}
        className={`text-white ${
          mode === 'dark' ? 'bg-gray-600' : 'bg-blue-500'
        } w-[75px] h-[75px] flex flex-col items-center fixed right-6 bottom-24 p-4 justify-center shadow-xl rounded-full`}
      >
        <FilterIcon className="w-5 h-5 shrink-0" />
        <span className="text-sm">{t('FILTER')}</span>
      </button>
      <BottomSheet
        isOpen={filterVisible}
        handleClose={() => setFilterVisible(false)}
        handleOpen={() => setFilterVisible(true)}
        minHeight={500}
      >
        <div className="relative z-50 p-6 pb-36">
          {isLecturer ? (
            <div
              className="flex flex-col items-center absolute right-7 z-60 cursor-pointer"
              onClick={changeFilter}
            >
              <UserGroupIcon className="w-8 h-8" />
              <span className="text-xs">{t('FILTER.STUDENT')}</span>
            </div>
          ) : (
            <div
              className="flex flex-col items-center absolute right-7 z-60 cursor-pointer"
              onClick={changeFilter}
            >
              <AcademicCapIcon className="w-8 h-8" />
              <span className="text-xs">{t('FILTER.LECTURER')}</span>
            </div>
          )}
          <p className="text-center text-3xl mb-8">{t('FILTER')}</p>
          {isLecturer ? (
            <LecturerFilter
              hideFilter={() => setFilterVisible(!filterVisible)}
              addFilterQueryToHistory={addFilterQueryToHistory}
            />
          ) : (
            <StudentFilter
              hideFilter={() => setFilterVisible(!filterVisible)}
              addFilterQueryToHistory={addFilterQueryToHistory}
            />
          )}
        </div>
      </BottomSheet>
    </>
  );
};

export default ScheduleFilter;
