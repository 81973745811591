import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import Cookies from 'js-cookie';

import { persistCache, LocalStorageWrapper } from 'apollo3-cache-persist';

const httpLink = createHttpLink({
  // uri: 'https://oyster-app-9qo9e.ondigitalocean.app/api/graphql',
  // uri: 'https://jellyfish-app-n5ol5.ondigitalocean.app/api/graphql',
  // uri: 'http://localhost:4000/api/graphql',
  // uri: 'https://sheltered-waters-60703.herokuapp.com/api/graphql',
  // uri: 'https://90a2-176-120-107-23.ngrok.io/api/graphql',
  uri: 'https://rozklad.uzhnu.edu.ua/api/graphql'
});

const authLink = setContext((_, { headers }) => {
  const token = Cookies.get('user-auth-token');
  return {
    headers: {
      ...headers,
      authorization: token ?? '',
      // authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        getBuilding(_, { args, toReference }: any) {
          return toReference({
            __typename: 'Building',
            _id: args._id,
          });
        },
      },
    },
  },
});

// persistCache({
//   cache,
//   storage: new LocalStorageWrapper(window.localStorage),
// });

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
});

export default client;
