import { useTheme } from '@mui/system';
import LanguageSwitcher from 'components/LanguageSwitcher';
import LogoutButton from 'components/LogoutButton';
import ThemeSwitcher from 'components/ThemeSwitcher';
import { useTranslation } from 'react-i18next';
import Header from '../../../components/Header';

const HomePage = () => {
  const { t } = useTranslation();

  const {
    palette: { mode },
  } = useTheme();

  return (
    <div
      className={`min-h-full ${
        mode === 'dark' ? 'bg-gray-800 text-white' : 'bg-violet-50'
      }  flex flex-col`}
    >
      <Header
        label="Головна"
        rightWidget={
          <>
            <LanguageSwitcher />
            <ThemeSwitcher />
            <LogoutButton />
          </>
        }
        bgColor={'bg-violet-50'}
        bgDarkModeColor={'bg-gray-800'}
      />
      <div className="p-4 pb-[93px] flex flex-col h-full flex-grow">
        <div className="flex flex-col h-full items-center justify-center flex-grow">
          <img
            src="/img/home/main.png"
            alt="poll"
            className="!block mx-auto w-64"
          />
          <div className="max-w-[280px] text-center justify-center font-medium mb-8">
            {t('HOME.WELCOME')}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
