import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../..';

import { AmplitudeClient } from 'amplitude-js';

export type SettingsState = {
  amplitude: {
    instance: AmplitudeClient | null;
  };
};

const initialState: SettingsState = {
  amplitude: {
    instance: null,
  },
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setAmplitudeInstance(state, action: PayloadAction<AmplitudeClient>) {
      state.amplitude.instance = action.payload;
    },
  },
});

export const { setAmplitudeInstance } = settingsSlice.actions;

const getState = (state: RootState) => state;

export const getSettings = createSelector(getState, state => state.settings);

export const getAmplitudeInstance = createSelector(
  getSettings,
  settings => settings.amplitude.instance,
);
