import { Chip } from '@mui/material';
import LinkIconProvider from 'components/LinkIconProvider';
import { Class } from 'generated/graphql';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ScheduleClassEnum,
  scheduleClassType,
  scheduleClassTypeColor,
  scheduleTimeConfig,
} from 'utils/schedule.utils';
import ClassGroupsInfo from '../ClassGroupsInfo';
import LecturerInfo from '../LecturerInfo';
import ScheduleNote from '../ScheduleNote';

export type BothClassProps = {
  class: Class;
  onCodeClick: (link: string) => void;
};

const BothClass: FC<BothClassProps> = ({
  class: { sequence, note, group, classroom, link, type, lecturer, title },
  onCodeClick,
}) => {
  const { t } = useTranslation();

  const isLecturerSchedule = window.location.href.includes('lecturer');

  return (
    <div className="p-2 flex flex-col gap-1">
      <div className="flex">
        <div className="mr-2">{sequence}.</div>
        {scheduleTimeConfig[sequence]}
        <div className="block ml-auto">
          <div className="flex items-center gap-2">
            {classroom?.slug}
            {!link?.includes('http') ? (
              <span
                className="cursor-pointer"
                onClick={() => onCodeClick(link!)}
              >
                {link}
              </span>
            ) : (
              <a href={link ?? ''}>
                <LinkIconProvider url={link ?? ''} className="w-6 h-6" />
              </a>
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-between">
        <div className="w-1/2 flex gap-1">
          <Chip
            color={scheduleClassTypeColor[type as ScheduleClassEnum]}
            label={t(scheduleClassType[type as ScheduleClassEnum])}
            size="small"
            className="font-bold self-start"
          />
          <div className="flex gap-1 items-center">
            {!!note ? <ScheduleNote note={note} /> : null}
            {isLecturerSchedule && <ClassGroupsInfo groups={group} />}
            {group.length > 1 && !isLecturerSchedule && (
              <ClassGroupsInfo groups={group} />
            )}
          </div>
        </div>
      </div>
      <div className="flex justify-between">
        <div className="w-1/2">{title}</div>
        {lecturer ? <LecturerInfo lecturer={lecturer} /> : null}
      </div>
    </div>
  );
};

export default BothClass;
