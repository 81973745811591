import {
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import { CameraIcon, XIcon } from '@heroicons/react/outline';
import { Box } from '@mui/system';
import { AmplitudeEvent, useAmplitude } from '../utils/hooks/useAmplitude';
import QrReader from 'react-qr-reader';
import { useTranslation } from 'react-i18next';

const QRCodeModal = () => {
  const { t } = useTranslation();

  const { sendEvent } = useAmplitude();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [link, setLink] = useState('');
  const [isConfirmVisible, setIsConfirmVisible] = useState(false);

  const {
    palette: { mode },
  } = useTheme();

  const showModal = () => {
    setIsModalVisible(true);
    sendEvent(AmplitudeEvent.ScanQRClicked);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    setLink('');
  };

  const handleDialogCancel = () => {
    setIsDialogVisible(false);
    setLink('');
  };

  const handleDialogConfirm = () => {
    setIsDialogVisible(false);
    window.location.assign(link);
  };

  const handleScan = (data: any) => {
    if (data) {
      setLink(data);
    }
    if (link && !isConfirmVisible) {
      setIsDialogVisible(true);
    }
  };

  const handleError = (err: any) => {
    console.error(err);
  };

  return (
    <>
      <div
        onClick={showModal}
        className="flex flex-col items-center w-14 h-14 rounded-full"
      >
        <CameraIcon className="w-6 h-6 shrink-0" />
        <span className="text-[0.7rem]">{t('QRCODE.SCAN')}</span>
        <span className="text-[0.7rem]">{t('QRCODE')}</span>
      </div>
      <Modal
        sx={{ outline: 0 }}
        open={isModalVisible}
        onClose={handleModalCancel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 325,
            bgcolor: 'background.paper',
            boxShadow: 24,
            borderRadius: '5px',
            p: 4,
          }}
        >
          <XIcon
            className="w-6 h-6 absolute right-[-10px] top-[-10px] bg-red-700 rounded-full text-white"
            onClick={handleModalCancel}
          />

          <Typography
            id="modal-modal-title"
            className={`${mode === 'dark' ? 'text-white' : 'text-black'}`}
          >
            Відскануйте QR-код
          </Typography>
          <QrReader
            onError={handleError}
            onScan={handleScan}
            style={{ width: '100%' }}
          />
        </Box>
      </Modal>
      <Dialog
        open={isDialogVisible}
        onClose={handleDialogCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Хочете перейти за цим посиланням?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <span className="break-words">{link}</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogCancel}>Ні</Button>
          <Button onClick={handleDialogConfirm}>Так</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default QRCodeModal;
